import { useFormState } from "react-hook-form";
import { IInputProps } from "../../interfaces/IInputProps";
import { IModel } from "../../interfaces/IModel";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { useState } from "react";
import ErrorInput from "./ErrorInput";

const PhoneInput = ({
  label,
  attribute,
  register,
  control,
  rules = {},
  size,
  placeholder,
  type,
  ...props
}: IInputProps<IModel>) => {
  const [touched, setTouched] = useState(false);
  const { errors } = useFormState({ control });

  const isPhoneValidate = (value: string) => {
    const attribute = value.replace(/[^\d]/g, "");
    if (attribute.length > 2 && attribute[2] === "9") {
      return attribute.length === 11;
    }
    return attribute.length === 10;
  };

  rules.validate = isPhoneValidate;

  return (
    <Form.Group
      className="mb-3 position-relative"
      controlId={`form${attribute}`}
    >
      <Form.Label>{label}</Form.Label>
      <Form.Control
        size={size || "lg"}
        type={type || "tel"}
        as={InputMask}
        mask="(99) 99999-9999"
        placeholder={placeholder || label}
        isInvalid={touched && !!errors[attribute]}
        onFocus={() => (!touched ? setTouched(true) : null)}
        {...register(attribute, rules)}
        {...props}
      />
      <ErrorInput error={errors[attribute]} />
    </Form.Group>
  );
};

export default PhoneInput;
