import { Container, Image } from "react-bootstrap";
import { ListWinners } from "./components/ListWinners";
import { useEffect, useState } from "react";
import FooterComponent from "../../components/FooterComponent";
import VoucherService from "../../services/VoucherService";
import { IVoucher } from "../../interfaces/IVoucher";
import moment from "moment";

const WinnersPage = () => {
  const [list, setList] = useState<IVoucher[]>([]);

  useEffect(() => {
    const untilDate = moment().subtract(1, "day").format("YYYY-MM-DD");

    VoucherService.getListWinnersUntilDate(untilDate).then((res) => {
      let list = res.success ? (res.body as IVoucher[]) : [];

      list.sort((a, b) => a.gameOpportunity?.invoice?.client?.name?.localeCompare(b.gameOpportunity?.invoice?.client?.name || "") || 0)

      setList(list);
    });
  }, []);

  const showWinnersList = () => {
    let now = Number.parseInt(moment().format("YYYYMMDD"));

    return now > 20240901;
  };

  const getDateUntil = () => {
    return moment().subtract(1, "day").format("DD/MM");
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_cadastro page_ganhadores">
          <div className="food-icons">
              <Image src="./images/icons/pao-ama.png" className="brioche" ></Image>
              <Image src="./images/icons/gota-ama.png" className="gota" ></Image>
              <Image src="./images/icons/pizza-ama.png" className="pizza" ></Image>
              <Image src="./images/icons/biscoito-ama.png" className="biscoito" ></Image>
              <Image src="./images/icons/festa-ama.png" className="festa" ></Image>
              <Image src="./images/icons/massa-ama.png" className="massa" ></Image>          
            </div>

          <div className="hero-inner">
            
              <div className="logo_campaign ">
                <Image src="./images/logo-campanha.png" className=" presente" />
                <Image src="./images/logo-brilho.png" className="img-fluid brilho" />
              </div>
              
              {/* GANHADORES DO SORTEIO SEMANAL */}
              <div className="wrapper-ganhadores ganhadores-sorteio ">
               
                <ul className="winners-list text-start">
                  {/* GANHADOR atual */}
                  
                  <li className="winner-item">
                    <div className="number">1</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">
                    Rosemary Paula de Souza 
                    </div>
                    <div className="winner-cpf">SP Capital </div>
                    <div className="winner-number">290066</div>
                    <div className="valor">01 (uma) Masseira da marca Braesi modelo AES-05</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">2</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">Gabriel Gomes da Costa Muniz  </div>
                    <div className="winner-cpf">SP Capital </div>
                    <div className="winner-number">336316</div>
                    <div className="valor">01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">3</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">Marcos Antônio Gomes Sousa  </div>
                    <div className="winner-cpf">Guarulhos</div>
                    <div className="winner-number">289476</div>
                    <div className="valor">01 (uma) Masseira da marca Braesi modelo AES-05</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">4</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">FABIANA MOITINHO DE SOUZA </div>
                    <div className="winner-cpf">Guarulhos</div>
                    <div className="winner-number">305006</div>
                    <div className="valor">01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">5</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">Walter Pereira Da Silva</div>
                    <div className="winner-cpf"> Interior </div>
                    <div className="winner-number">297939</div>
                    <div className="valor">01 (uma) Masseira da marca Braesi modelo AES-05</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">6</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">Laércio Corte </div>
                    <div className="winner-cpf">Interior</div>
                    <div className="winner-number">308561</div>
                    <div className="valor">01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">7</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">Adriano Bolonha </div>
                    <div className="winner-cpf">Oeste</div>
                    <div className="winner-number">398268</div>
                    <div className="valor">01 (uma) Masseira da marca Braesi modelo AES-05</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">8</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">Ricardo Alves Moreira </div>
                    <div className="winner-cpf">Oeste</div>
                    <div className="winner-number">537577</div>
                    <div className="valor">01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v</div>
                  </li>


                  <li className="winner-item ">
                    <div className="number">9</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">Maria de Lourdes Correa</div>
                    <div className="winner-cpf">Vale do Paraiba</div>
                    <div className="winner-number">343588</div>
                    <div className="valor">01 (uma) Masseira da marca Braesi modelo AES-05</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">10</div>
                    <div className="draw-date">08/01/2025</div>
                    <div className="winner-name">Adilson César de Morais</div>
                    <div className="winner-cpf">Vale do Paraiba</div>
                    <div className="winner-number">356638</div>
                    <div className="valor">01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v</div>
                  </li>
                </ul>
              </div>

              {/* GANHADORES DO SORTEIO INSTANTÂNEO */}
              <div className="wrapper-ganhadores ganhadores-vales d-none">
                <h4 className="title title-ganhadores">
                  Ganhadores do Vale-compras de R$500,00
                </h4>

                {showWinnersList() ? (
                  <>
                    <h5 className="">
                      Ganhadores de 01/09 até {getDateUntil()}{" "}
                    </h5>
                    <ListWinners itens={list} />
                  </>
                ) : (
                  <h5 className="">LISTA DISPONÍVEL A PARTIR DE 02/09</h5>
                )}
              </div>

              <div className="selos  ">
                  <div className="selo-5un selo">
                    <Image
                      src="./images/5unidades.png"
                      className="img-fluid"
                    />
                    <Image
                      src="./images/massa-e-forno.png"
                      className="img-fluid massa"
                    />
                  </div>
                  <div className="selo selo-numeroextra">
                    <Image
                      src="./images/selectprod.png"
                      className="img-fluid"
                    />
                  </div>
              </div> 
                         
          </div>
          
        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default WinnersPage;
