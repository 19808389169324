import { useFormState } from "react-hook-form";
import { IInputProps } from "../../interfaces/IInputProps";
import { IModel } from "../../interfaces/IModel";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import ErrorInput from "./ErrorInput";

const TextInput = ({
  label,
  attribute,
  register,
  control,
  rules = {},
  size,
  placeholder,
  type,
  ...props
}: IInputProps<IModel>) => {
  const [touched, setTouched] = useState(false);
  const {errors } = useFormState({control});

  return (
    <Form.Group className="mb-3 position-relative" controlId={`form-${attribute}`}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        size={size || "lg"}
        type={type || "text"}
        placeholder={placeholder || label}
        isInvalid={touched && !!errors[attribute]}
        onFocus={() => !touched ? setTouched(true) : null}
        {...register(attribute, rules)}
        {...props}
      />
      <ErrorInput error={errors[attribute]} />
    </Form.Group>
  );
};

export default TextInput;
